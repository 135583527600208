import React, { useState } from 'react';
import { PageProps, graphql } from 'gatsby';
import Img from 'gatsby-image';
import qs from 'qs';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';

import { LocaleNameSpaceKeys } from '../../../../localization/translations';
import brokerResourcesMenu from '../../../../data/page-menus/broker-centre';
import PageMenu from '../../../../components/PageMenu/PageMenu';
import Layout, { LayoutOptions, HeroSectionColors } from '../../../../components/Layout/Layout';
import Input, { InputType } from '../../../../components/primitives/Input/Input';
import { IconTypes } from '../../../../components/Icon/Icon';
import Button from '../../../../components/primitives/Button/Button';
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';
import CommonData from '../../../../data/common/common';
import ExternalLink from '../../../../components/ExternalLink/ExternalLink';
import DownloadBlock from '../../../../components/DownloadBlock/DownloadBlock';
import { useLocaleContext } from '../../../../context/Locale';

import NativeSelect, {
  SelectOption,
} from '../../../../components/primitives/NativeSelect/NativeSelect';

import './style.scss';

type Props = PageProps & WithTranslation;

enum InputErrorType {
  EMPTY = 'EMPTY',
  INVALID_CHARACTERS = 'INVALID_CHARACTERS',
}

const ApprovedAppraiserList: React.FC<Props> = (props) => {
  const { t, data: graphqlData } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('approvedAppraiserList.seo.title'),
    },
    page: {
      title: t('approvedAppraiserList.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.PARROT_GREEN,
        heroImage: {
          fileName: 'hero-image-4.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const initialState = {
    value: '',
    isInvalid: false,
    validationMessage: '',
  };

  const [appraisalsInput, setAppraisalsInput] = useState(initialState);
  const [results, setResult] = useState<any>([]);

  const [formState, setFormState] = useState({
    submitted: false,
    submitting: false,
    shouldDisplayErrorMessage: false,
  });

  const searchTypeOptions: SelectOption[] = [
    {
      label: t('approvedAppraiserList.form.searchBy.appraiserName'),
      value: 'appraiserName',
    },
    {
      label: t('approvedAppraiserList.form.searchBy.province'),
      value: 'province',
    },
    {
      label: t('approvedAppraiserList.form.searchBy.coverageArea'),
      value: 'coverageArea',
    },
  ];

  const [searchType, setSearchType] = useState(searchTypeOptions[0].value);
  const handleSearchTypeChange = (value: string) => setSearchType(value);

  const validateInput = (value: string): { valid: boolean; errorType: InputErrorType | null } => {
    if (value.trim()) {
      return { valid: true, errorType: null };
    }

    return { valid: false, errorType: InputErrorType.EMPTY };
  };

  const getErrorValidationMessage = (
    inputErrorType: InputErrorType,
    validationMessage: { emptyField: string; invalidCharacters: string }
  ): string => {
    if (inputErrorType === InputErrorType.EMPTY) {
      return validationMessage.emptyField;
    }

    return validationMessage.invalidCharacters;
  };

  const validateAndParseValue = () => {
    const validationResults = validateInput(appraisalsInput.value);

    const validatedState = {
      ...appraisalsInput,
      validationMessage: '',
      isInvalid: false,
    };

    validatedState.isInvalid = !validationResults.valid;

    if (!validationResults.valid && validationResults.errorType) {
      const validationMessage = getErrorValidationMessage(
        validationResults.errorType,
        t('approvedAppraiserList.form.appraisalsInput.validationMessage', { returnObjects: true })
      );
      validatedState.validationMessage = validationMessage;
    }

    return validatedState;
  };

  const handleFormChange = (value: string) => {
    setAppraisalsInput((prevState) => ({
      ...prevState,
      value,
    }));
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    event.persist();

    const appraisalsInputTempState = validateAndParseValue();

    setAppraisalsInput((prevState) => ({
      ...prevState,
      ...appraisalsInputTempState,
    }));

    if (appraisalsInputTempState.isInvalid) {
      return false;
    }

    const finalObject = {
      [searchType]: appraisalsInputTempState.value,
    };

    setFormState((prevState) => ({
      ...prevState,
      submitting: true,
      submitted: false,
    }));

    await fetch(
      `${process.env.GATSBY_API_URL}${CommonData.apis.appraisals}?${qs.stringify(finalObject)}`,
      {
        method: 'GET',
        // @ts-ignore
        headers: {
          'Content-Type': 'application/json',
          client_id: process.env.GATSBY_LENDING_AREAS_CLIENT_ID,
          client_secret: process.env.GATSBY_LENDING_AREAS_CLIENT_SECRET,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.AppraisalList)) {
          setResult(data.AppraisalList);
        } else {
          setFormState((prevState) => ({
            ...prevState,
            submitting: false,
            submitted: true,
            shouldDisplayErrorMessage: true,
          }));
          return;
        }
        setFormState((prevState) => ({
          ...prevState,
          submitting: false,
          submitted: true,
          shouldDisplayErrorMessage: false,
        }));
      })
      .catch((err) => {
        console.error(`Failed: `, err);
        setFormState((prevState) => ({
          ...prevState,
          submitting: false,
          submitted: true,
          shouldDisplayErrorMessage: true,
        }));
      });

    return true;
  };

  const getResultRows = () => {
    return results
      .filter((row: any) => row.DoNotUse !== 'true')
      .map((row: any) => {
        return (
          <tr key={row.AppraiserName}>
            <td>{row.AppraiserName}</td>
            <td>{row.Province}</td>
            <td>{row.CoverageArea}</td>
            <td>{row.PhoneNumber}</td>
            <td>
              {row.Website && row.Website !== 'n/a' ? (
                <ExternalLink href={row.Website}>{row.Website}</ExternalLink>
              ) : (
                row.Website
              )}
            </td>
          </tr>
        );
      });
  };

  const downloadLinksAppraisalBestPractices = [
    {
      to: `/downloads/approved-appraiser-list/PRESS_AppraisalBestPractice_10July2020.pdf`,
      label: t(`${LocaleNameSpaceKeys.COMMON}:english`),
    },
    {
      to: `/downloads/approved-appraiser-list/PRESS_PratiquesExemplairesEvaluation_10July2020.pdf`,
      label: t(`${LocaleNameSpaceKeys.COMMON}:french`),
    },
  ];

  const downloadLinksAppraisalRequirements = [
    {
      to: `/downloads/approved-appraiser-list/AppraisalRequirements_13Sept2022.pdf`,
      label: t(`${LocaleNameSpaceKeys.COMMON}:english`),
    },
    {
      to: `/downloads/approved-appraiser-list/ListesExigencesEvaluation_20Dec2022.pdf`,
      label: t(`${LocaleNameSpaceKeys.COMMON}:french`),
    },
  ];

  const ariaLabels = {
    email: t(`${LocaleNameSpaceKeys.COMMON}:email`),
    tollFree: t(`${LocaleNameSpaceKeys.COMMON}:tollFree`),
    phoneNumber: t(`${LocaleNameSpaceKeys.COMMON}:phoneNumber`),
  };

  return (
    <Layout options={layoutOptions} className="ApprovedAppraiserList">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <div className="ApprovedAppraiserList__generic-gap">
              <p>{t('approvedAppraiserList.body')}</p>
            </div>
            <div className="ApprovedAppraiserList__generic-gap">
              <div className="grid-container preferred-container">
                <div className="row row-wrap">
                  <div className="column column-30">
                    <img
                      alt="NAS"
                      src={
                        (graphqlData as any).nas.edges.find((item: any) =>
                          item.node.name.includes(activeLocaleTag)
                        ).node.publicURL
                      }
                      style={{
                        width: '100%',
                        height: '100%',
                        maxWidth: '120px',
                        margin: '2rem 1.5rem',
                      }}
                    />
                  </div>
                  <div className="column column-60">
                    <p>
                      <Trans
                        t={t}
                        i18nKey="approvedAppraiserList.nas"
                        values={{
                          email: CommonData.email.nas,
                          phone: CommonData.telephoneNumber.nas,
                        }}
                      >
                        <ExternalLink
                          href={`mailto:${CommonData.email.nas}`}
                          aria-label={`${ariaLabels.email} ${CommonData.email.nas}`}
                        />
                        <ExternalLink
                          href={`tel:${CommonData.telephoneNumber.nas}`}
                          aria-label={`${ariaLabels.tollFree} ${CommonData.telephoneNumber.nas}`}
                        />
                      </Trans>
                    </p>
                  </div>
                </div>
                <div className="row row-wrap">
                  <div className="column column-30">
                    <img
                      alt="RPS"
                      src={
                        (graphqlData as any).rps.edges.find((item: any) =>
                          item.node.name.includes(activeLocaleTag)
                        ).node.publicURL
                      }
                      style={{
                        width: '100%',
                        height: '100%',
                        maxWidth: '120px',
                        margin: '2rem 1.5rem',
                      }}
                    />
                  </div>
                  <div className="column column-60">
                    <p>
                      <Trans
                        t={t}
                        i18nKey="approvedAppraiserList.rps"
                        values={{
                          email: CommonData.email.rps,
                          phone: CommonData.telephoneNumber.rps,
                        }}
                      >
                        <ExternalLink
                          href={`mailto:${CommonData.email.rps}`}
                          aria-label={`${ariaLabels.email} ${CommonData.email.rps}`}
                        />
                        <ExternalLink
                          href={`tel:${CommonData.telephoneNumber.rps}`}
                          aria-label={`${ariaLabels.tollFree} ${CommonData.telephoneNumber.rps}`}
                        />
                      </Trans>
                    </p>
                  </div>
                </div>
                <div className="row row-wrap">
                  <div className="column column-30">
                    <Img
                      alt="Solidify"
                      fixed={(graphqlData as any).solidify.childImageSharp.fixed}
                      imgStyle={{ width: '100%', height: '100%', maxWidth: '150px' }}
                    />
                  </div>
                  <div className="column column-60">
                    <p>
                      <Trans
                        t={t}
                        i18nKey="approvedAppraiserList.solidify"
                        values={{
                          email: CommonData.email.solidify,
                          phone: CommonData.telephoneNumber.solidify,
                        }}
                      >
                        <ExternalLink
                          href={`mailto:${CommonData.email.solidify}`}
                          aria-label={`${ariaLabels.email} ${CommonData.email.solidify}`}
                        />
                        <ExternalLink
                          href={`tel:${CommonData.telephoneNumber.solidify}`}
                          aria-label={`${ariaLabels.tollFree} ${CommonData.telephoneNumber.solidify}`}
                        />
                      </Trans>
                    </p>
                  </div>
                </div>
                <div className="row row-wrap">
                  <div className="column column-30">
                    <Img
                      alt="Value Connect"
                      fixed={(graphqlData as any).valueConnect.childImageSharp.fixed}
                      imgStyle={{ width: '100%', height: '100%', maxWidth: '150px' }}
                    />
                  </div>
                  <div className="column column-60">
                    <p>
                      <Trans
                        t={t}
                        i18nKey="approvedAppraiserList.valueConnect"
                        values={{
                          email: CommonData.email.valueConnect,
                          phone: CommonData.telephoneNumber.valueConnect,
                        }}
                      >
                        <ExternalLink
                          href={`mailto:${CommonData.email.valueConnect}`}
                          aria-label={`${ariaLabels.email} ${CommonData.email.valueConnect}`}
                        />
                        <ExternalLink
                          href={`tel:${CommonData.telephoneNumber.valueConnect}`}
                          aria-label={`${ariaLabels.tollFree} ${CommonData.telephoneNumber.valueConnect}`}
                        />
                      </Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="ApprovedAppraiserList__generic-gap">
              <p>{t('approvedAppraiserList.bodyTwo')}</p>
            </div>
            <p>{t('approvedAppraiserList.form.appraisalsInput.label')}</p>
            <form onSubmit={handleSubmit} className="ApprovedAppraiserList__form">
              <div className="row row-wrap">
                <div className="column column-60">
                  <Input
                    id="userInput"
                    isInvalid={appraisalsInput.isInvalid}
                    validationMessage={appraisalsInput.validationMessage}
                    options={{
                      type: InputType.TEXT,
                      label: <br />,
                      inputProps: {
                        name: 'name',
                        value: appraisalsInput.value,
                      },
                    }}
                    required
                    onChange={handleFormChange}
                  />
                </div>
                <div className="column column-35">
                  <NativeSelect
                    id="search-type"
                    options={{
                      label: t('approvedAppraiserList.form.searchBy.label'),
                      selectProps: {
                        value: searchType,
                        name: 'searchType',
                      },
                    }}
                    selectOptions={searchTypeOptions}
                    onChange={handleSearchTypeChange}
                  />
                </div>
              </div>

              {formState.submitted && formState.shouldDisplayErrorMessage && (
                <ErrorMessage>{t('lendingAreas.form.submissionValidation.error')}</ErrorMessage>
              )}
              <Button
                id="apraisals-search"
                type="submit"
                iconOptions={{ icon: IconTypes.SEARCH }}
                styleOptions={{ isInline: true }}
                disabled={formState.submitting}
              >
                {formState.submitting
                  ? t('lendingAreas.form.submittingLabel')
                  : t('lendingAreas.form.submitLabel')}
              </Button>
            </form>
          </section>
          <aside className="column column-33">
            <PageMenu
              menu={brokerResourcesMenu}
              menuTitle={t(`${LocaleNameSpaceKeys.COMMON}:pageMenuTitles.brokerResources`)}
            />
          </aside>
          <section className="column column-100">
            {formState.submitted && results.length > 0 && (
              <div className="Appraisals__results ApprovedAppraiserList__generic-gap">
                <table>
                  <thead>
                    <tr>
                      <th>
                        {t('approvedAppraiserList.form.searchBy.appraiserName').toUpperCase()}
                      </th>
                      <th>{t('approvedAppraiserList.form.searchBy.province').toUpperCase()}</th>
                      <th>{t('approvedAppraiserList.form.searchBy.coverageArea').toUpperCase()}</th>
                      <th>{t(`${LocaleNameSpaceKeys.COMMON}:phoneNumber`).toUpperCase()}</th>
                      <th>{t(`${LocaleNameSpaceKeys.COMMON}:website`).toUpperCase()}</th>
                    </tr>
                  </thead>
                  <tbody>{getResultRows()}</tbody>
                </table>
              </div>
            )}
            {formState.submitted && results.length === 0 && (
              <p>{t('approvedAppraiserList.form.noMatch')}</p>
            )}
          </section>
          <section className="column column-60">
            <div className="ApprovedAppraiserList__generic-gap">
              <div className="DownloadBlock">
                <h4>{t('approvedAppraiserList.autoValuationModel.title')}</h4>
                <p>
                  <Trans t={t} i18nKey="approvedAppraiserList.autoValuationModel.body">
                    <ExternalLink href={CommonData.websites.brokerPortal.avm[activeLocaleTag]} />
                  </Trans>
                </p>
              </div>
            </div>
            <DownloadBlock
              heading={t('approvedAppraiserList.appraisalBestPractices.title')}
              body={
                <p>
                  <Trans t={t} i18nKey="approvedAppraiserList.appraisalBestPractices.body" />
                </p>
              }
              downloadLinks={downloadLinksAppraisalBestPractices}
            />
            <DownloadBlock
              heading={t('approvedAppraiserList.appraisalRequirements.title')}
              body={
                <p>
                  <Trans t={t} i18nKey="approvedAppraiserList.appraisalRequirements.body" />
                </p>
              }
              downloadLinks={downloadLinksAppraisalRequirements}
            />
          </section>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    solidify: file(relativePath: { eq: "solidify.png" }) {
      childImageSharp {
        fixed(width: 150, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    valueConnect: file(relativePath: { eq: "value-connect.png" }) {
      childImageSharp {
        fixed(width: 150, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    rps: allFile(filter: { relativePath: { regex: "/rps/" }, ext: { eq: ".png" } }) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
    nas: allFile(filter: { relativePath: { regex: "/nas/" }, ext: { eq: ".png" } }) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
`;

export default withTranslation(LocaleNameSpaceKeys.BROKER_PORTAL)(ApprovedAppraiserList);
